.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
          animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0,0,0,0.08);
          box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0,0,0,0.08);
}
.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}
.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
          animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
          box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}
.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}
.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}
.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}
.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}
.flatpickr-calendar:focus {
  outline: 0;
}
.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}
.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0,0,0,0.9);
  fill: rgba(0,0,0,0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0,0,0,0.9);
  fill: rgba(0,0,0,0.9);
}
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}
.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
/*
      /*rtl:begin:ignore*/
/*
      */
  left: 0;
/*
      /*rtl:end:ignore*/
/*
      */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
/*
      /*rtl:begin:ignore*/
/*
      */
  right: 0;
/*
      /*rtl:end:ignore*/
/*
      */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}
.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}
.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57,57,57,0.15);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.numInputWrapper span:hover {
  background: rgba(0,0,0,0.1);
}
.numInputWrapper span:active {
  background: rgba(0,0,0,0.2);
}
.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}
.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57,57,57,0.6);
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57,57,57,0.6);
  top: 40%;
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(0,0,0,0.5);
}
.numInputWrapper:hover {
  background: rgba(0,0,0,0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}
.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
}
.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(0,0,0,0.05);
}
.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0,0,0,0.9);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0,0,0,0.9);
}
.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0,0,0,0.5);
  background: transparent;
  pointer-events: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0,0,0,0.05);
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}
.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 28px;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0,0,0,0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-weight: bolder;
}
.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}
.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 307.875px;
}
.flatpickr-days:focus {
  outline: 0;
}
.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}
.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
          box-shadow: -1px 0 0 #e6e6e6;
}
.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
      -ms-flex-preferred-size: 14.2857143%;
          flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}
.flatpickr-day.today {
  border-color: #959ea9;
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
          box-shadow: -10px 0 0 #569ff7;
}
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
          box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57,57,57,0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57,57,57,0.1);
}
.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
          box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}
.flatpickr-day.hidden {
  visibility: hidden;
}
.rangeMode .flatpickr-day {
  margin-top: 1px;
}
.flatpickr-weekwrapper {
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
          box-shadow: 1px 0 0 #e6e6e6;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}
.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57,57,57,0.3);
  background: transparent;
  cursor: default;
  border: none;
}
.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}
.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}
.flatpickr-input[readonly] {
  cursor: pointer;
}
@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

/**!
  Visualization Classes for debugging the vertical-collection
  */
.vertical-collection-visual-debugger {
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: left;
  background: rgba(50, 50, 50, 1);
  width: 125px;
}

.vertical-collection-visual-debugger .vc_visualization-container {
  transform: scale(0.25);
  left: 0;
  position: relative;
}


.vertical-collection-visual-debugger .vc_visualization-screen {
  position: absolute;
  background: transparent;
  box-sizing: content-box;
  border-top: 2px dashed yellow;
  border-bottom: 2px dashed yellow;
  width: 500px;
}

.vertical-collection-visual-debugger .vc_visualization-scroll-container {
  position: absolute;
  width: 500px;
  background: rgba(100, 230, 100, 0.65);
}

.vertical-collection-visual-debugger .vc_visualization-item-container {
  position: absolute;
  width: 500px;
  background: rgba(255, 255, 255, .15);
}

.vertical-collection-visual-debugger .vc_visualization-virtual-component {
  box-sizing: border-box;
  background: rgba(230, 100, 230, 0.6);
  border: 1px dotted #bbb;
  border-top: 0px;
  color: #fff;
  text-align: center;
  font-size: 2.5em;
  width: 250px;
}

.vertical-collection-visual-debugger .vc_visualization-virtual-component:first-of-type {
  border-top: 1px dotted #bbb;
}

.vertical-collection-visual-debugger .vc_visualization-virtual-component.culled {
  background: transparent;
}

.occluded-content {
  display: block;
  position: relative;
  width: 100%;

  /* prevents margin overflow on item container */
  min-height: 0.01px;

  /* hides text visually while still being readable by screen readers */
  color: rgba(0,0,0,0);
}

table .occluded-content,
tbody .occluded-content,
thead .occluded-content,
tfoot .occluded-content {
  display: table-row;
  position: relative;
  width: 100%;
}

ul .occluded-content,
ol .occluded-content {
  display: list-item;
  position: relative;
  width: 100%;
  list-style-type: none;
  height: 0;
}

:root {
  --ecn-container-position: 10px;
  --ecn-container-width: 80%;
  --ecn-container-max-with: 400px;

  --ecn-icon-width: 30px;
  --ecn-icon-position: 10px;
  --ecn-icon-color: rgba(255, 255, 255, 0.74);
  --ecn-icon-lighten-background: rgba(255, 255, 255, 0.2);
  --ecn-countdown-lighten-background: rgba(255, 255, 255, 0.4);
  --ecn-notification-max-height: 800px;
  --ecn-notification-border-radius: 3px;

  /* Colours */
  --ecn-green: #64ce83;
  --ecn-blue: #3ea2ff;
  --ecn-orange: #ff7f48;
  --ecn-red: #e74c3c;

  /* Spacing */
  --ecn-spacing-1: .5rem;
  --ecn-spacing-2: 1rem;
}

/* Base */

.ember-cli-notifications-notification__container {
  position: fixed;
  margin: 0 auto;
  width: 80%;
  width: var(--ecn-container-width);
  max-width: 400px;
  max-width: var(--ecn-container-max-with);
}

/* Position */

.ember-cli-notifications-notification__container--top {
  top: 10px;
  top: var(--ecn-container-position);
  right: 0;
  left: 0;
}

.ember-cli-notifications-notification__container--top-left {
  top: 10px;
  top: var(--ecn-container-position);
  right: auto;
  left: 10px;
  left: var(--ecn-container-position);
}

.ember-cli-notifications-notification__container--top-right {
  top: 10px;
  top: var(--ecn-container-position);
  right: 10px;
  right: var(--ecn-container-position);
  left: auto;
}

.ember-cli-notifications-notification__container--bottom {
  right: 0;
  bottom: 10px;
  bottom: var(--ecn-container-position);
  left: 0;
}

.ember-cli-notifications-notification__container--bottom-left {
  right: auto;
  bottom: 10px;
  bottom: var(--ecn-container-position);
  left: 10px;
  left: var(--ecn-container-position);
}

.ember-cli-notifications-notification__container--bottom-right {
  right: 10px;
  right: var(--ecn-container-position);
  bottom: 10px;
  bottom: var(--ecn-container-position);
  left: auto;
}

/* Values */
.ember-cli-notifications-notification__container .c-notification {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  position: relative;
  overflow: hidden;
  border-radius: var(--ecn-notification-border-radius);
  border-bottom: 1rem;
  color: white;
  max-height: var(--ecn-notification-max-height);
  -webkit-animation: notification-hide 250ms cubic-bezier(.33859, -.42, 1, -.22), notification-shrink 250ms 250ms cubic-bezier(.5, 0, 0, 1);
          animation: notification-hide 250ms cubic-bezier(.33859, -.42, 1, -.22), notification-shrink 250ms 250ms cubic-bezier(.5, 0, 0, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  margin-bottom: var(--ecn-spacing-2);
}
.ember-cli-notifications-notification__container .c-notification--clickable {
  cursor: pointer;
}
.ember-cli-notifications-notification__container .c-notification--in {
  -webkit-animation: notification-show 180ms cubic-bezier(.175, .885, .32, 1.27499);
          animation: notification-show 180ms cubic-bezier(.175, .885, .32, 1.27499);
}
.ember-cli-notifications-notification__container .c-notification__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: var(--ecn-spacing-1) var(--ecn-spacing-2);
  word-break: break-word;
}
.ember-cli-notifications-notification__container .c-notification__content a {
  color: #fff;
  text-decoration: underline;
}
.ember-cli-notifications-notification__container .c-notification__icon {
  padding: var(--ecn-spacing-1) 0;
  text-align: center;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  background-color: var(--ecn-icon-lighten-background);
  width: var(--ecn-icon-width);
  color: var(--ecn-icon-color);
}
.ember-cli-notifications-notification__container .c-notification__svg {
  width: 16px;
  height: 16px;
  vertical-align: text-top;
}
.ember-cli-notifications-notification__container .c-notification__close {
  margin-left: var(--ecn-spacing-2);
  -ms-flex-item-align: start;
      align-self: flex-start;
  opacity: .74;
  cursor: pointer;
}
.ember-cli-notifications-notification__container .c-notification__close:hover,
.ember-cli-notifications-notification__container .c-notification__close:focus {
  opacity: 1;
}
.ember-cli-notifications-notification__container .c-notification__countdown {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--ecn-countdown-lighten-background);
  width: 0;
  height: 4px;
  -webkit-animation: notification-countdown linear 1;
          animation: notification-countdown linear 1;
}
/* Theme */
.ember-cli-notifications-notification__container .c-notification--info {
  background-color: var(--ecn-blue);
}
.ember-cli-notifications-notification__container .c-notification--success {
  background-color: var(--ecn-green);
}
.ember-cli-notifications-notification__container .c-notification--warning {
  background-color: var(--ecn-orange);
}
.ember-cli-notifications-notification__container .c-notification--error {
  background-color: var(--ecn-red);
}
/* Keyframes */
@-webkit-keyframes notification-show {
  0% {
    opacity: 0;
    -webkit-transform: perspective(450px) translate(0, -30px) rotateX(90deg);
            transform: perspective(450px) translate(0, -30px) rotateX(90deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: perspective(450px) translate(0, 0) rotateX(0deg);
            transform: perspective(450px) translate(0, 0) rotateX(0deg);
  }
}
@keyframes notification-show {
  0% {
    opacity: 0;
    -webkit-transform: perspective(450px) translate(0, -30px) rotateX(90deg);
            transform: perspective(450px) translate(0, -30px) rotateX(90deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: perspective(450px) translate(0, 0) rotateX(0deg);
            transform: perspective(450px) translate(0, 0) rotateX(0deg);
  }
}
@-webkit-keyframes notification-shrink {
  0% {
    opacity: 0;
    max-height: var(--ecn-notification-max-height);
    -webkit-transform: scale(.8);
            transform: scale(.8);
  }

  100% {
    opacity: 0;
    max-height: 0;
    -webkit-transform: scale(.8);
            transform: scale(.8);
  }
}
@keyframes notification-shrink {
  0% {
    opacity: 0;
    max-height: var(--ecn-notification-max-height);
    -webkit-transform: scale(.8);
            transform: scale(.8);
  }

  100% {
    opacity: 0;
    max-height: 0;
    -webkit-transform: scale(.8);
            transform: scale(.8);
  }
}
@-webkit-keyframes notification-hide {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(.8);
            transform: scale(.8);
  }
}
@keyframes notification-hide {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(.8);
            transform: scale(.8);
  }
}
@-webkit-keyframes notification-countdown {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}
@keyframes notification-countdown {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

:root {
  --ecn-container-position: 10px;
  --ecn-container-width: 80%;
  --ecn-container-max-with: 400px;

  --ecn-icon-width: 30px;
  --ecn-icon-position: 10px;
  --ecn-icon-color: rgba(255, 255, 255, 0.74);
  --ecn-icon-lighten-background: rgba(255, 255, 255, 0.2);
  --ecn-countdown-lighten-background: rgba(255, 255, 255, 0.4);
  --ecn-notification-max-height: 800px;
  --ecn-notification-border-radius: 3px;

  /* Colours */
  --ecn-green: #64ce83;
  --ecn-blue: #3ea2ff;
  --ecn-orange: #ff7f48;
  --ecn-red: #e74c3c;

  /* Spacing */
  --ecn-spacing-1: .5rem;
  --ecn-spacing-2: 1rem;
}

/* Base */

.ember-cli-notifications-notification__container {
  position: fixed;
  margin: 0 auto;
  width: 80%;
  width: var(--ecn-container-width);
  max-width: 400px;
  max-width: var(--ecn-container-max-with);
}

/* Position */

.ember-cli-notifications-notification__container--top {
  top: 10px;
  top: var(--ecn-container-position);
  right: 0;
  left: 0;
}

.ember-cli-notifications-notification__container--top-left {
  top: 10px;
  top: var(--ecn-container-position);
  right: auto;
  left: 10px;
  left: var(--ecn-container-position);
}

.ember-cli-notifications-notification__container--top-right {
  top: 10px;
  top: var(--ecn-container-position);
  right: 10px;
  right: var(--ecn-container-position);
  left: auto;
}

.ember-cli-notifications-notification__container--bottom {
  right: 0;
  bottom: 10px;
  bottom: var(--ecn-container-position);
  left: 0;
}

.ember-cli-notifications-notification__container--bottom-left {
  right: auto;
  bottom: 10px;
  bottom: var(--ecn-container-position);
  left: 10px;
  left: var(--ecn-container-position);
}

.ember-cli-notifications-notification__container--bottom-right {
  right: 10px;
  right: var(--ecn-container-position);
  bottom: 10px;
  bottom: var(--ecn-container-position);
  left: auto;
}

/* Values */

.ember-cli-notifications-notification__container .c-notification {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  border-radius: var(--ecn-notification-border-radius);
  border-bottom: 1rem;
  color: white;
  max-height: 800px;
  max-height: var(--ecn-notification-max-height);
  -webkit-animation: notification-hide 250ms cubic-bezier(.33859, -.42, 1, -.22), notification-shrink 250ms 250ms cubic-bezier(.5, 0, 0, 1);
          animation: notification-hide 250ms cubic-bezier(.33859, -.42, 1, -.22), notification-shrink 250ms 250ms cubic-bezier(.5, 0, 0, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  margin-bottom: 1rem;
  margin-bottom: var(--ecn-spacing-2);
}

.ember-cli-notifications-notification__container .c-notification--clickable {
  cursor: pointer;
}

.ember-cli-notifications-notification__container .c-notification--in {
  -webkit-animation: notification-show 180ms cubic-bezier(.175, .885, .32, 1.27499);
          animation: notification-show 180ms cubic-bezier(.175, .885, .32, 1.27499);
}

.ember-cli-notifications-notification__container .c-notification__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: .5rem 1rem;
  padding: var(--ecn-spacing-1) var(--ecn-spacing-2);
  word-break: break-word;
}

.ember-cli-notifications-notification__container .c-notification__content a {
  color: #fff;
  text-decoration: underline;
}

.ember-cli-notifications-notification__container .c-notification__icon {
  padding: .5rem 0;
  padding: var(--ecn-spacing-1) 0;
  text-align: center;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  background-color: rgba(255, 255, 255, 0.2);
  background-color: var(--ecn-icon-lighten-background);
  width: 30px;
  width: var(--ecn-icon-width);
  color: rgba(255, 255, 255, 0.74);
  color: var(--ecn-icon-color);
}

.ember-cli-notifications-notification__container .c-notification__svg {
  width: 16px;
  height: 16px;
  vertical-align: text-top;
}

.ember-cli-notifications-notification__container .c-notification__close {
  margin-left: 1rem;
  margin-left: var(--ecn-spacing-2);
  -ms-flex-item-align: start;
      align-self: flex-start;
  opacity: .74;
  cursor: pointer;
}

.ember-cli-notifications-notification__container .c-notification__close:hover,
.ember-cli-notifications-notification__container .c-notification__close:focus {
  opacity: 1;
}

.ember-cli-notifications-notification__container .c-notification__countdown {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
  background-color: var(--ecn-countdown-lighten-background);
  width: 0;
  height: 4px;
  -webkit-animation: notification-countdown linear 1;
          animation: notification-countdown linear 1;
}

/* Theme */

.ember-cli-notifications-notification__container .c-notification--info {
  background-color: #3ea2ff;
  background-color: var(--ecn-blue);
}

.ember-cli-notifications-notification__container .c-notification--success {
  background-color: #64ce83;
  background-color: var(--ecn-green);
}

.ember-cli-notifications-notification__container .c-notification--warning {
  background-color: #ff7f48;
  background-color: var(--ecn-orange);
}

.ember-cli-notifications-notification__container .c-notification--error {
  background-color: #e74c3c;
  background-color: var(--ecn-red);
}

/* Keyframes */

@-webkit-keyframes notification-show {
  0% {
    opacity: 0;
    -webkit-transform: perspective(450px) translate(0, -30px) rotateX(90deg);
            transform: perspective(450px) translate(0, -30px) rotateX(90deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: perspective(450px) translate(0, 0) rotateX(0deg);
            transform: perspective(450px) translate(0, 0) rotateX(0deg);
  }
}

@keyframes notification-show {
  0% {
    opacity: 0;
    -webkit-transform: perspective(450px) translate(0, -30px) rotateX(90deg);
            transform: perspective(450px) translate(0, -30px) rotateX(90deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: perspective(450px) translate(0, 0) rotateX(0deg);
            transform: perspective(450px) translate(0, 0) rotateX(0deg);
  }
}

@-webkit-keyframes notification-shrink {
  0% {
    opacity: 0;
    max-height: 800px;
    max-height: var(--ecn-notification-max-height);
    -webkit-transform: scale(.8);
            transform: scale(.8);
  }

  100% {
    opacity: 0;
    max-height: 0;
    -webkit-transform: scale(.8);
            transform: scale(.8);
  }
}

@keyframes notification-shrink {
  0% {
    opacity: 0;
    max-height: 800px;
    max-height: var(--ecn-notification-max-height);
    -webkit-transform: scale(.8);
            transform: scale(.8);
  }

  100% {
    opacity: 0;
    max-height: 0;
    -webkit-transform: scale(.8);
            transform: scale(.8);
  }
}

@-webkit-keyframes notification-hide {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(.8);
            transform: scale(.8);
  }
}

@keyframes notification-hide {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(.8);
            transform: scale(.8);
  }
}

@-webkit-keyframes notification-countdown {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

@keyframes notification-countdown {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

.ember-select {
  position: relative;
}

.es-control {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: #333;
  cursor: text;
  display: table;
  height: 36px;
  outline: none;
  overflow: hidden;
  padding: 6px 12px;
  position: relative;
  table-layout: fixed;
  width: 100%;
  z-index: 5;
}

.es-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.es-multiple .es-control {
  padding: 4px 12px;
}

.es-multiple .es-input > input {
  width: auto;
}

.es-open > .es-control {
  background: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #b3b3b3 #ccc #d9d9d9;
}

.es-focus:not(.es-open) > .es-control {
  border-color: #0099e6;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 5px -1px rgba(0, 136, 204, 0.5);
}

.es-selections {
  margin-right: 1rem;
}

.es-selections span {
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  margin: 2px 0;
  padding: 0 6px;
}

.es-selections span:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}

.es-input > input {
  border: 0 none;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  width: 100%;
}

.es-select,
.es-select input,
.es-select .es-control .es-input > input {
  cursor: pointer;
}

/* Hide IE `clear` on input */
.es-input > input::-ms-clear {
  display: none;
}

.es-focus .es-input > input {
  cursor: text;
}

/* Clear */
.es-clear-zone {
  color: #999;
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 20px;
}

.es-clear-zone:hover {
  color: #D0021B;
}

.es-clear {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
}

/* Options */
.es-options {
  background-color: #fff;
  border: 1px solid #ccc;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -2px;
  max-height: 200px;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 10;
}

.es-option {
  cursor: pointer;
  display: block;
  padding: 0.25rem 1.5rem;
}

.es-option:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.es-options .es-highlight {
  background-color: #f2f9fc;
  color: #333;
}

/* Dropdown */
.es-arrow-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 20px;
}

.es-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: 0;
  width: 0;
}

.es-open .es-arrow,
.es-arrow-zone:hover > .es-arrow {
  border-top-color: #666;
}

/* Groups */
.es-group {
  font-weight: bold;
  padding: 1rem 0 0 0.5rem;
}

/* Pseudo elements to disable IE `helpfulness`.
   Avoids clear and show password buttons. */
::-ms-clear,
::-ms-reveal {
  display: none;
}

.ember-table {
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.ember-table .ember-table-overflow {
  overflow: auto;
  max-height: 100%;
  max-width: 100%;
}

.ember-table table {
  border-spacing: 0;
  table-layout: fixed;
  box-sizing: border-box;
}

.ember-table td,
.ember-table th {
  box-sizing: border-box;
}

.ember-table td.is-fixed-left,
.ember-table td.is-fixed-right,
.ember-table th.is-fixed-left,
.ember-table th.is-fixed-right {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.ember-table td.ember-table__text-align-left,
.ember-table th.ember-table__text-align-left {
  text-align: left;
}

.ember-table td.ember-table__text-align-center,
.ember-table th.ember-table__text-align-center {
  text-align: center;
}

.ember-table td.ember-table__text-align-right,
.ember-table th.ember-table__text-align-right {
  text-align: right;
}

.ember-table th {
  z-index: 2;
}

.ember-table th:not(.is-fixed-right) .et-header-resize-area {
  right: 0;
}

.ember-table th.is-fixed-right .et-header-resize-area {
  left: 0;
}

.ember-table td.is-fixed-left,
.ember-table td.is-fixed-right {
  z-index: 3;
}

.ember-table th.is-fixed-left,
.ember-table th.is-fixed-right {
  z-index: 4;
}

.ember-table th.is-sortable {
  cursor: pointer;
}

.ember-table thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  box-sizing: border-box;
}

.ember-table tbody {
  box-sizing: border-box;
}

.ember-table tfoot {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 2;
  box-sizing: border-box;
}

.ember-table .scroll-indicator {
  position: absolute;
  z-index: 5;
}

.ember-table .scroll-indicator__left,
.ember-table .scroll-indicator__right {
  top: 0;
  width: 8px;
  height: 100%;
}

.ember-table .scroll-indicator__left {
  background: linear-gradient(to right, rgba(168, 168, 168, 0.4), rgba(168, 168, 168, 0));
}

.ember-table .scroll-indicator__right {
  right: 0;
  background: linear-gradient(to left, rgba(168, 168, 168, 0.4), rgba(168, 168, 168, 0));
}

.ember-table .scroll-indicator__top,
.ember-table .scroll-indicator__bottom {
  left: 0;
  width: 100%;
  height: 8px;
}

.ember-table .scroll-indicator__top {
  top: 0;
  background: linear-gradient(to bottom, rgba(168, 168, 168, 0.4), rgba(168, 168, 168, 0));
}

.ember-table .scroll-indicator__bottom {
  bottom: 0;
  background: linear-gradient(to top, rgba(168, 168, 168, 0.4), rgba(168, 168, 168, 0));
}

.ember-table.et-unselectable {
  user-select: none;
}

.ember-table .et-header-resize-area {
  cursor: col-resize;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
}

.ember-table .et-speech-only {
  display: none !important;
}

@media speech {
  .ember-table .et-speech-only {
    display: block !important;
  }
}

.ember-table .ember-table-loading-more {
  display: inline-block;
}

.ember-tooltip-base {
  display: none;
  height: 0;
  width: 0;
  position: absolute;
}

.ember-tooltip,
.ember-popover {
  margin-bottom: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  position: absolute;
  z-index: 2;

  font-size: 14px;
  font-family: inherit;
  border-radius: 3px;
}

.ember-tooltip {
  max-width: 200px;
  padding: 6px 10px;
  color: #fff;
  background: #3a3c47;
  text-shadow: -1px -1px 0 rgba(0,0,0,0.2);
  white-space: normal; /* IE doesnt support initial so fall back to normal */
  white-space: initial;
}

.ember-popover {
  color: #000;
  background-color: #fff;
  padding: 10px 20px;
  border: 1px solid #ccc;
}

.ember-popover[aria-hidden="false"] {
  pointer-events: auto;
  cursor: initial;
  -webkit-touch-callout: auto;
  -webkit-user-select: auto;
  user-select: auto;
}

.ember-tooltip-arrow,
.ember-popover-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border: 5px solid transparent;
}

.ember-tooltip, .ember-popover {
  opacity: 0;
  -webkit-transition: opacity ease-out, margin ease-out;
  transition: opacity ease-out, margin ease-out;
  transition-duration: 200ms;
}

.ember-tooltip.ember-tooltip-effect-none,
.ember-popover.ember-tooltip-effect-none,
.ember-tooltip.ember-tooltip-show,
.ember-popover.ember-tooltip-show {
  opacity: 1;
}

.ember-tooltip[x-placement^="top"] .ember-tooltip-arrow,
.ember-popover[x-placement^="top"] .ember-popover-arrow {
  border-bottom-width: 0;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.ember-tooltip[x-placement^="top"] .ember-tooltip-arrow {
  border-top-color: #3a3c47;
}

.ember-popover[x-placement^="top"] .ember-popover-arrow {
  border-top-color: #ccc;
}

.ember-tooltip[x-placement^="right"] .ember-tooltip-arrow,
.ember-popover[x-placement^="right"] .ember-popover-arrow {
  border-left-width: 0;
  left: -5px;
  top: calc(50% - 5px);
  margin-right: 0;
  margin-left: 0;
}

.ember-tooltip[x-placement^="right"] .ember-tooltip-arrow {
  border-right-color: #3a3c47;
}

.ember-popover[x-placement^="right"] .ember-popover-arrow {
  border-right-color: #ccc;
}

.ember-tooltip[x-placement^="bottom"] .ember-tooltip-arrow,
.ember-popover[x-placement^="bottom"] .ember-popover-arrow {
  border-top-width: 0;
  top: -5px;
  left: calc(50% - 5px);
  margin-bottom: 0;
  margin-top: 0;
}

.ember-tooltip[x-placement^="bottom"] .ember-tooltip-arrow {
  border-bottom-color: #3a3c47;
}

.ember-popover[x-placement^="bottom"] .ember-popover-arrow {
  border-bottom-color: #ccc;
}

.ember-tooltip[x-placement^="left"] .ember-tooltip-arrow,
.ember-popover[x-placement^="left"] .ember-popover-arrow {
  border-right-width: 0;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.ember-tooltip[x-placement^="left"] .ember-tooltip-arrow {
  border-left-color: #3a3c47;
}

.ember-popover[x-placement^="left"] .ember-popover-arrow {
  border-left-color: #ccc;
}
